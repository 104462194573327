<template>
    <tr :class="rowClass" v-bind="rowDataProps">
        <td
            v-if="!props.item.IsDistributorDuplicate"
            :title="props.item.Distributor.DisplayName"
            class="w-40 overflow-hidden text-center"
            :rowspan="distributorRowspan">
            <div>
                <div class="hidden sm:block">
                    <a
                        v-if="props.item.ProductUrl"
                        :href="props.item.ProductUrl"
                        data-ga="s:Part"
                        target="_blank"
                        rel="noopener nofollow"
                        :title="props.item.Distributor.DisplayName"
                        class="flex justify-center items-start"
                        @click="emitClick('referral')">
                        <EnhancedImage
                            :css-class="imageClass"
                            :height="imageHeight"
                            :width="imageWidth"
                            :src="props.item.Distributor.LogoUrl"
                            :alt="props.item.Distributor.DisplayName" />
                    </a>
                    <div v-else class="flex justify-center items-start">
                        <EnhancedImage
                            :css-class="imageClass"
                            :height="imageHeight"
                            :width="imageWidth"
                            :src="props.item.Distributor.LogoUrl"
                            :alt="props.item.Distributor.DisplayName" />
                    </div>
                </div>
                <div class="block sm:hidden text-sm">
                    <a
                        v-if="props.item.ProductUrl"
                        :href="props.item.ProductUrl"
                        data-ga="s:Part"
                        target="_blank"
                        rel="noopener nofollow"
                        class="block mb-4"
                        :title="props.item.Distributor.DisplayName"
                        @click="emitClick('referral')">
                        {{ props.item.Distributor.Name }}
                    </a>
                    <span v-else>{{ props.item.Distributor.Name }}</span>
                </div>
                <div
                    v-if="props.duplicateCount"
                    class="flex justify-center mt-2 text-sm link-blue whitespace-nowrap"
                    @click="expanded = !expanded">
                    <button v-if="!expanded">{{ $t("Search.ShowDistributorDuplicates") }}</button>
                    <button v-else>{{ $t("Search.HideDistributorDuplicates") }}</button>
                </div>
            </div>
        </td>
        <td v-if="showRohs" v-hidden="'xs,sm,md,lg-1270'" class="text-center">
            <div v-for="(rohs, index) in getRoHS(props.item.RoHS, $t)" :key="index">
                {{ rohs }}
            </div>
        </td>
        <td v-hidden="'xs'" class="overflow-hidden text-center">
            <a
                v-if="props.item.DatasheetUrl"
                :href="props.item.DatasheetUrl"
                data-ga="s:Datasheet"
                target="_blank"
                rel="noopener nofollow"
                class="inline-block"
                :title="$t('Global.Datasheet')"
                @click="emitClick('datasheet')">
                <img
                    :src="pdfIconUrl"
                    :alt="$t('Global.DatasheetImageAltText', [props.item.Manufacturer.Name, props.item.PartNumber])"
                    loading="lazy" />
            </a>
        </td>
        <td v-hidden="'xs,sm,md'" class="text-center break-all">
            {{ props.item.DistributorPartNumber }}
        </td>
        <PartPackagingColumn :part-result="props.item" />
        <td class="relative min-w-24 overflow-hidden text-center !pb-6">
            <PartStockColumn :result="item" />
        </td>
        <td class="text-nowrap">
            <PartPriceBreaksColumn :prices="props.item.Prices" />
        </td>
        <td class="print:hidden">
            <div v-if="props.item.ProductUrl" class="text-center">
                <a
                    v-if="props.item.ShowBuyButton"
                    :class="
                        'btn btn-lg btn-bg-primary rounded-full uppercase font-bold text-sm min-w-20 inline-block leading-6 ' +
                        props.item.BuyClass
                    "
                    :href="props.item.ProductUrl"
                    data-ga="s:Buy"
                    target="_blank"
                    rel="noopener nofollow"
                    :title="props.item.BuyText"
                    @click="emitClick('referral')">
                    {{ props.item.BuyText }}
                </a>
                <a
                    v-if="!props.item.ShowBuyButton"
                    :class="
                        'btn btn-lg btn-bg-accent rounded-full uppercase font-bold text-sm min-w-20 inline-block leading-6 ' +
                        props.item.ViewClass
                    "
                    :href="props.item.ProductUrl"
                    data-ga="s:View"
                    target="_blank"
                    rel="noopener nofollow"
                    :title="props.item.ViewText"
                    @click="emitClick('referral')">
                    {{ props.item.ViewText }}
                </a>
                <span class="inline-block p-2">{{ props.item.Currency }}</span>
            </div>
        </td>
    </tr>
</template>
<script setup lang="ts">
type ClickSource = "referral" | "datasheet";

const config = useRuntimeConfig();
const cdnUrl = config.public.cdnUrl;
const pdfIconUrl = cdnUrl + "/assets/images/adobe_pdf_file_icon_24x24.png";

const props = defineProps<{
    item: PartResult;
    duplicateCount?: number;
    showRohs: boolean;
    clickHash?: string;
    clickDataSource: string;
    showChild?: boolean;
}>();

const expanded = defineModel<boolean | undefined>("expanded", { required: true });

const emit = defineEmits<{ click: [ClickSource] }>();

const rowClass = computed(() => {
    return {
        hidden: props.item.IsDistributorDuplicate && !props.showChild,
        duplicate: props.item.IsDistributorDuplicate,
        expanded: expanded.value,
    };
});

const rowDataProps = computed(() => {
    return {
        "data-source": props.clickDataSource,
        "data-dist": props.item.Distributor.Name + " [" + props.item.Distributor.Id + "]",
        "data-mfr": props.item.Manufacturer.Name + " [" + props.item.Manufacturer.Id + "]",
        "data-mpn": props.item.PartNumber,
        "data-cur": props.item.Currency,
        "data-stock-qty": props.item.QuantityOnHand,
        "data-sortorder": props.item.SortOrder,
        "data-parent-sortorder": props.item.ParentSortOrder,
        "data-is-distributor-duplicate": props.item.IsDistributorDuplicate,
        "data-hash": props.clickHash,
    };
});

// synchronize with SearchDistributorLogo.vue
const isSponsorLogo = computed(() => props.item.LogoCssClass.includes("Sponsor"));
const imageHeight = computed(() => (isSponsorLogo.value ? "40" : "36"));
const imageWidth = computed(() => (isSponsorLogo.value ? "94" : "86"));
const imageClass = computed(() => (isSponsorLogo.value ? "max-h-10 max-w-[94px]" : "max-h-9 max-w-[86px]"));

const emitClick = (source: ClickSource) => emit("click", source);
const getRoHS = SearchHelpers.getRoHS;

const distributorRowspan = computed(() => (expanded.value && props.duplicateCount ? props.duplicateCount + 1 : 1));
</script>
