<template>
    <Dialog v-model:open="isOpen">
        <DialogScrollContent id="part-results-modal" class="md:max-w-4xl">
            <div v-if="part" class="part-result-modal" v-bind="dataProps">
                <div class="flex justify-between flex-col md:flex-row gap-2">
                    <div class="flex gap-2">
                        <div v-if="part.ImageUrl">
                            <a :href="part.PartUrl" target="_blank">
                                <PartImage size="lg" :part="part" />
                            </a>
                        </div>
                        <div>
                            <div class="text-muted">{{ part.Manufacturer.Name }}</div>
                            <h2 class="mt-0">
                                <a :href="part.PartUrl" target="_blank">{{ part.PartNumber }}</a>
                            </h2>

                            <div class="flex flex-col gap-2">
                                <div>{{ part.Description }}</div>
                                <div class="flex gap-2" @click.stop>
                                    <PartLifecycleRisk v-if="lifecycleRisk !== null" :risk="lifecycleRisk" />
                                    <PartSupplyChainRisk v-if="supplyChainRisk !== null" :risk="supplyChainRisk" />
                                </div>
                                <a
                                    v-if="part.DatasheetUrl"
                                    v-bind="dataProps"
                                    :href="part.DatasheetUrl"
                                    class="flex gap-2 items-center"
                                    data-ga="s:Datasheet"
                                    target="_blank"
                                    rel="nofollow"
                                    @click="emitClick('datasheet')">
                                    <img :src="dataSheetImage" />
                                    <span>{{ $t("Global.Datasheet") }}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <PartChartPreview
                        :part="part"
                        :part-key="key"
                        :load-chart-data="true"
                        preview-height="120"
                        preview-width="300"
                        @click="partChartDialog?.open(part, key, $event)" />
                    <PartChartDialog ref="partChartDialog" />
                </div>
                <Spinner v-if="loading" size="lg" />
                <table v-if="!loading" class="mt-4 table table-search-results text-xs">
                    <thead>
                        <tr class="text-center">
                            <th v-html="$t('Search.AuthorizedDistributor')" />
                            <th v-hidden="'xs,sm,md'" class="hidden min-[1270px]:table-cell">
                                {{ $t("Search.RoHS") }}
                            </th>
                            <th v-hidden="'xs'" class="hidden sm:table-cell">
                                {{ $t("Global.Datasheet") }}
                            </th>
                            <th v-hidden="'xs,sm,md'" class="hidden lg:table-cell">
                                {{ $t("Search.Supplier_Number") }}
                            </th>
                            <th v-hidden="'xs,sm,md'" class="hidden min-[1270px]:table-cell">
                                {{ $t("Search.PackagingHeader") }}
                            </th>
                            <th>{{ $t("Global.Stock") }}</th>
                            <th>{{ $t("Search.Pricing") }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <PartResultsRow
                            v-for="item in distributorResults"
                            :key="item.SortOrder"
                            v-model:expanded="expanded[item.SortOrder]"
                            :show-child="expanded[item.ParentSortOrder]"
                            :item="item"
                            :click-hash="part.PartClickHash"
                            :duplicate-count="partDuplicateCounts[item.SortOrder]"
                            :show-rohs="true"
                            :click-data-source="props.analyticsSource"
                            @click="onPartResultRowClick" />
                    </tbody>
                </table>
            </div>
        </DialogScrollContent>
    </Dialog>
</template>
<script setup lang="ts">
export type ClickSource = "datasheet" | "part" | "distributor_datasheet" | "distributor_part";

defineExpose({ openCategoryPart, openSimilarPart });
const emit = defineEmits<{ click: [source: ClickSource, part: CommonPart | null] }>();
const api = useApi();
const config = useRuntimeConfig();
const props = defineProps<{ analyticsSource: ANALYTICS_SOURCE }>();

const distributorResults = ref<PartResult[]>([]);
const part = ref<CommonPart | null>(null);
const category = ref<Category | null>(null);
const isOpen = ref(false);
const loading = ref(false);
const lifecycleRisk = ref<number | null>(null);
const supplyChainRisk = ref<number | null>(null);
const dataSheetImage = config.public.cdnUrl + "/assets/images/adobe_pdf_file_icon_24x24.png";
const expanded = ref<Record<string, boolean>>({});
const partChartDialog = useTemplateRef("partChartDialog");

const key = computed(() => {
    if (!part.value) return "";
    return `results-modal-${part.value.PartNumber}-${part.value.Manufacturer.Id}`;
});

const dataProps = computed(() => {
    if (!part.value) return {};
    const manufacturer = part.value.Manufacturer;
    const distributor = part.value.DatasheetDistributor;
    return {
        "data-dist": distributor ? `${distributor.Name} [${distributor.Id}]` : undefined,
        "data-mfr": `${manufacturer.Name} [${manufacturer.Id}]`,
        "data-mpn": part.value.PartNumber,
        "data-hash": part.value.PartClickHash,
        "data-source": props.analyticsSource,
    };
});

const partDuplicateCounts = computed(() => getPartDuplicateCounts(distributorResults.value));

function getDistributorName(distributorId?: number, distributorResults?: PartResult[]) {
    if (distributorId && Array.isArray(distributorResults)) {
        const result = distributorResults.find((r) => r.Distributor?.Id == distributorId);
        if (result) return result.Distributor.DisplayName;
    }
    return "";
}

async function openCategoryPart(
    part: ParametricSearchPart,
    category: Category,
    otherData: { ManufacturerName: string; PartUrl: string }
) {
    const inputPart: CommonPart = {
        PartId: 0, // not available or needed here
        ImageUrl: part.ImageUrl,
        IsSelfHostedImage: part.IsSelfHostedImage,
        PartUrl: otherData.PartUrl,
        PartNumber: part.PartNumber,
        Description: part.Description,
        DatasheetUrl: part.DatasheetUrl,
        Manufacturer: { Id: part.ManufacturerId, Name: otherData.ManufacturerName },
        PartClickHash: part.PartClickHash,
        DatasheetDistributor: {
            Id: part.DatasheetDistributorId,
            Name: getDistributorName(part.DatasheetDistributorId, part.DistributorResults),
        },
    };

    return open(inputPart, category);
}

async function openSimilarPart(part: SimilarPart, category: Category) {
    const detail = part.PartDetail;
    const inputPart: CommonPart = {
        PartId: 0, // not available or needed here
        ImageUrl: part.ImageUrl,
        IsSelfHostedImage: part.IsSelfHostedImage,
        PartUrl: part.PartUrl,
        PartNumber: detail.PartNumber,
        Description: detail.Description,
        DatasheetUrl: detail.DatasheetUrl,
        Manufacturer: { Id: part.Manufacturer.Id, Name: part.Manufacturer.DisplayName },
        DatasheetDistributor: detail.DatasheetDistributorId
            ? {
                  Id: detail.DatasheetDistributorId,
                  Name: getDistributorName(detail.DatasheetDistributorId, part.DistributorResults),
              }
            : undefined,
    };

    return open(inputPart, category);
}

async function open(inputPart: CommonPart, categoryValue: Category) {
    isOpen.value = true;
    loading.value = true;
    part.value = inputPart;
    category.value = categoryValue;
    lifecycleRisk.value = null;
    supplyChainRisk.value = null;

    try {
        const data = await api<ParametricSearchPart>(`/api/part/search`, {
            query: { partNumber: inputPart.PartNumber, manufacturerId: inputPart.Manufacturer.Id },
        });

        part.value.PartClickHash = data.PartClickHash;
        distributorResults.value = data.DistributorResults;
        supplyChainRisk.value = data.SupplyChainRisk;
        lifecycleRisk.value = data.LifecycleRisk;
    } catch (error) {
        // do nothing if error
        console.error(error);
    } finally {
        loading.value = false;
    }
}

function emitClick(source: ClickSource) {
    emit("click", source, part.value);
}

function onPartResultRowClick(source: string) {
    emitClick(source === "datasheet" ? "distributor_datasheet" : "distributor_part");
}
</script>
